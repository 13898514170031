import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PermissionsGuard } from '@thg-procure-team/procure-common-ui/platform/security';

import { LayoutNotFoundComponent } from '@procure-warehouse/core/layouts/layout-not-found/layout-not-found.component';
import { PermissionLevel } from '@procure-warehouse/core/user/domain';

const routes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('./feature/dashboard/dashboard.module').then((m) => m.DashboardModule),
    data: { permissions: { 'Warehouse Dashboard': PermissionLevel.FULL_ALL_BUSINESS_UNITS } },
    canLoad: [PermissionsGuard],
  },
  {
    path: 'configuration',
    loadChildren: () => import('./feature/configuration/configuration.module').then((m) => m.ConfigurationModule),
    data: { permissions: { 'Warehouse configuration': PermissionLevel.READ_ONLY_ALL_BUSINESS_UNITS } },
    canLoad: [PermissionsGuard],
  },
  {
    path: 'configuration/add',
    loadChildren: () =>
      import('./feature/configuration-add/configuration-add.module').then((m) => m.ConfigurationAddModule),
    data: { permissions: { 'Warehouse configuration': PermissionLevel.FULL_ALL_BUSINESS_UNITS } },
    canLoad: [PermissionsGuard],
  },
  {
    path: 'reserved-slots',
    loadChildren: () =>
      import('./feature/reserved-slots/reserved-slots.module').then((m) => m.ReservedSlotsModule),
    data: { permissions: { 'Warehouse reserved slots': PermissionLevel.READ_ONLY_ALL_BUSINESS_UNITS } },
    canLoad: [PermissionsGuard],
  },
  {
    path: 'reserved-slots/add',
    loadChildren: () =>
      import('./feature/reserved-slots-add/reserved-slots-add.module').then((m) => m.ReservedSlotsAddModule),
    data: { permissions: { 'Warehouse reserved slots': PermissionLevel.FULL_ALL_BUSINESS_UNITS } },
    canLoad: [PermissionsGuard],
  },
  {
    path: 'delivery-booking',
    loadChildren: () =>
      import('./feature/delivery-booking/delivery-booking.module').then((m) => m.DeliveryBookingModule),
    data: { permissions: { 'Delivery bookings': PermissionLevel.READ_ONLY_ALL_BUSINESS_UNITS } },
    canLoad: [PermissionsGuard],
  },
  {
    path: 'delivery-collections',
    loadChildren: () =>
      import('./feature/delivery-collections/delivery-collections.module').then(
        (m) => m.DeliveryCollectionsModule,
      ),
    data: { permissions: { 'Delivery Collections': PermissionLevel.READ_ONLY_ALL_BUSINESS_UNITS } },
    canLoad: [PermissionsGuard],
  },
  {
    path: 'delivery-collections/:id',
    loadChildren: () =>
      import('./feature/delivery-collection-details/delivery-collection-details.module').then(
        (m) => m.DeliveryCollectionDetailsModule,
      ),
    data: { permissions: { 'Delivery Collections': PermissionLevel.READ_ONLY_ALL_BUSINESS_UNITS } },
    canLoad: [PermissionsGuard],
  },
  {
    path: 'delivery-booking/summary',
    loadChildren: () =>
      import('./feature/delivery-booking-summary/delivery-booking-summary.module').then(
        (m) => m.DeliveryBookingSummaryModule,
      ),
    data: { permissions: { 'Delivery bookings': PermissionLevel.READ_ONLY_ALL_BUSINESS_UNITS } },
    canLoad: [PermissionsGuard],
  },
  {
    path: 'delivery-booking/day-overview',
    loadChildren: () =>
      import('./feature/delivery-booking-day/delivery-booking-day.module').then((m) => m.DeliveryBookingDayModule),
    data: { permissions: { 'Delivery bookings': PermissionLevel.READ_ONLY_ALL_BUSINESS_UNITS } },
    canLoad: [PermissionsGuard],
  },
  {
    path: 'pending-asns',
    loadChildren: () => import('./feature/pending-asns/pending-asns.module').then((m) => m.PendingAsnsModule),
    data: { permissions: { 'Pending ASNs': PermissionLevel.FULL_ALL_BUSINESS_UNITS } },
    canLoad: [PermissionsGuard],
  },
  {
    path: 'check-in',
    loadChildren: () => import('./feature/check-in/check-in.module').then((m) => m.CheckInModule),
    data: { permissions: { Gatehousing: PermissionLevel.FULL_ALL_BUSINESS_UNITS } },
    canLoad: [PermissionsGuard],
  },
  { path: '404', component: LayoutNotFoundComponent },
  { path: '**', redirectTo: 'dashboard' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
